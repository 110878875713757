export const ACCOUNTS = '/accounts';
export const ACCOUNT_LOCKED = '/account-locked';
export const APPS = '/apps';
export const AUS_REG_LANDING = '/registration';
export const BIOMETRIC_AUTH_SUCCESS = '/biometric/success';
export const BIOMETRIC_SIGN_IN = '/biometric/sign-in';
export const CREATE_ACCOUNT = '/create-account';
export const CREATE_ACCOUNT_AUS = '/create-account-820ae01a-249f-4853-9499-6f3a24707487';
export const HOME = '/home';
export const LANDING = '/';
export const LOGOUT = '/logout';
export const MAGIC_LINK = '/magic-link';
export const ONBOARDING = '/onboarding';
export const ONBOARDING_TERMS_CONDITIONS = '/rux/terms-conditions';
export const OOBBAS = '/oobbas';
export const PROFILE = '/profile';
export const RUX_AUTH_SUCCESS = '/rux/auth/success';
export const RUX_AUTH_UNSUCCESS = '/rux/auth/unsuccess';
export const RUX_BIOMETRIC_VERIFY = '/rux/biometric/verify';
export const RUX_LANDING = '/rux';
export const RUX_MORE_DETAILS = '/rux/more-details';
export const RUX_VERIFY = '/rux/verify';
export const SETUP_FACEID = '/setup-faceid';
export const SIGN_IN = '/sign-in';
export const SUPPORT = '/support';
export const SYSTEM_DOWN = '/system-down';
export const TERMS_CONDITIONS = '/terms-conditions';
export const UNEXPECTED_ERROR = '/unexpected-error';
export const VERIFY = '/verify';
export const VERIFY_OTP = '/verify-otp';