import { IBasRegisteredDevice, IBiomtericCallbacks } from 'hooks/useBiometric/types';
import service from 'libs/axios';
import { CustomAdaptiveAuthenticationUI, CustomManageRegistrationsUI, CustomMethodUIFactory, CustomSuggestRegistrationUI } from './helper';
import { localDataStorage } from "../storage/LocalStorage";
import { Toast } from 'components/CustomToast';

const env = {
  reg_endpoint: 'https://mtf.api.identitysolutions.mastercard.com/bas/connections/reg',
  auth_endpoint: 'https://mtf.api.identitysolutions.mastercard.com/bas/connections/auth',
};

class Service {
  appSdk: any;
  initalizationStatus: 'not_started' | 'started' | 'error' | 'completed' = 'not_started';
  mAdaptiveCore: any;
  mAdaptiveUI: any;
  devices: IBasRegisteredDevice[] = [];

  verifyParams = '';

  constructor() {
    window.biometricCallbacks = {
      onRegisteredDevicesListner: (basDevices) => {
        this.devices = basDevices;
      },
      // onAuthenticationError: (error) => {
      //   console.log('Biometric error: ', error);
      // }
    };
  }

  async generateToken() {
    const response = await service({
      method: 'get',
      url: `customers/biometrics`,
    });
    const { data } = response;
    return data.token;
  }

  async init({
    forceInitalization = false,
    onRegisteredDevicesListner = (bas: IBasRegisteredDevice[]) => {
      //
    },
  }) {
    if (onRegisteredDevicesListner) {
      window.biometricCallbacks.onRegisteredDevicesListner = onRegisteredDevicesListner;
    }

    return new Promise((resolve, reject) => {
      try {
        if (forceInitalization || !this.appSdk || !this.mAdaptiveCore) {
          this.appSdk = new window.AppSdk();
          this.appSdk.regEndpoint = env.reg_endpoint;
          this.appSdk.authEndpoint = env.auth_endpoint;
          this.appSdk.mode = window.AppSdk.MODE_NATIVE;
          this.appSdk
            .init()
            .then(() => {
              // Adaptive UI initialization
              this.mAdaptiveUI = new window.AdaptiveUI({
                regEndpoint: env.reg_endpoint,
                authEndpoint: env.auth_endpoint,
              });
              this.mAdaptiveCore = new window.AdaptiveCore();
              this.mAdaptiveCore.regEndpoint = env.reg_endpoint;
              this.mAdaptiveCore.authEndpoint = env.auth_endpoint;
              this.mAdaptiveCore
                .init()
                .then(() => {
                  resolve(true);
                })
                .catch(reject);
            })
            .catch(reject);
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  setCorrelationId(extras: any) {
    extras = extras || {};
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    extras[window.AppSdk.EXTRAS_KEY_CORRELATION_ID] = uuid;
    return extras;
  }

  async getDeviceId(): Promise<string> {
    return await window.AppSdkInfo.getDeviceId();
  }

  async login(data: any) {
    const res = await service({
      method: 'post',
      // Uncomment when backend make changes
      // url: `/customers/verify?${this.verifyParams}`,
      url: '/customers/verify',
      data: {
        sessionKey: data.sessionKey,
      },
    });
    this.verifyParams = ''

    return res;
  }

  async handleError(error: any) {
    if (error.outcome === 'CANCELLED') {
      Toast.error({
        title: 'Something went wrong',
        message: 'Biometric authentication was cancelled.',
      })
      return error
    } else {
      Toast.error({
        title: 'Something went wrong',
        message: 'An unknown error occurred. Please try again later.',
      })
    }
  }

  async passwordlessSignin(userName: string) {
    try {
      const sessionData = {
        userName,
      };
      let extras = {};
      extras = this.setCorrelationId(extras);
      const adaptiveUI = new window.AdaptiveUI({
        regEndpoint: env.reg_endpoint,
        authEndpoint: env.auth_endpoint,
      });
      const view = adaptiveUI.getAuthenticationView(sessionData, null, extras);
      const res = await view.enable(window.AutoStart.AUTO_FIDO);

      const data = {
        sessionKey: res.sessionData?.sessionKey,
        username: res.username,
      };

      if (data.sessionKey) {
        await this.login(data);
      }

      return data;
    } catch (error: any) {
      this.handleError(error)
    }
  }

  async register() {
    const token = await this.generateToken();
    const isOnProfilePage = window.location.href.includes('/profile')
    let result: any;
    let error: any = null;
    try {
      const extras = this.setCorrelationId({});

      const suggestRegController = new window.SuggestRegistrationController(
        {
          sessionKey: token,
        },
        this.mAdaptiveCore,
        extras
      );
      const customSuggestRegistrationUI = new CustomSuggestRegistrationUI(suggestRegController);

      suggestRegController.processAnswer(customSuggestRegistrationUI, window.SuggestRegisterUIStatus.YES);
      result = await suggestRegController.getResult();
    } catch (err) {
      Toast.error({
        title: 'Something went wrong',
        message: `An error occured while trying to set up biometric authentication. ${isOnProfilePage ? 'Please try again later.' : 'Please continue another way.'}`
      })
      error = err;
    }
    return { result, error };
  }

  async getRegisteredDevice() {
    try {
      const token = await this.generateToken();

      let extras = {
        options: { needDetails: 3 },
      };

      extras = this.setCorrelationId(extras);
      const controller = new window.ManageRegistrationsController(
        {
          sessionKey: token,
        },
        env.reg_endpoint,
        env.auth_endpoint,
        extras
      );
      const manageRegistrationUI = new CustomManageRegistrationsUI(controller);
      controller.onRefresh(manageRegistrationUI);
    } catch (error) {
      this.handleError(error)
      console.log('error: ', error);
    }
  }

  async registerWithQrCode(params: IBiomtericCallbacks) {
    const token = await this.generateToken();

    window.biometricCallbacks = { ...params, ...(window.biometricCallbacks || {}) };

    const mainController = new CustomMethodUIFactory();
    window.MethodUIFactory.setInstance(mainController);

    const webUrl = window.document.location.origin + '/oobbas?weboob=REG';
    let extras = {
      qrType: window.QRType.UNIVERSAL_ANY_RP,
      oobRefId: 'Register FIDO2 Authenticator',
      webUrl,
      [window.AppSdk.EXTRA_KEY_QR_SUPPORTED]: true,
    };

    extras = this.setCorrelationId(extras);
    this.appSdk.mode = window.AppSdk.MODE_OOB;

    return this.appSdk
      .register(
        {
          sessionKey: token,
        },
        extras,
        null
      )
      .finally(() => {
        window.biometricCallbacks = {};
      });
  }

  async qrAuthorize(isRegistration = false) {
    const appSdk = new window.AppSdk();
    appSdk.regEndpoint = env.reg_endpoint;
    appSdk.authEndpoint = env.auth_endpoint;
    appSdk.srcPage = window.location.origin + window.location.pathname;
    const oobRefId = appSdk.parseOobData(window.location.href);
    await appSdk.init(window.AppSdk.PROTOCOL_FIDO2);

    const appName = window.location.href.split('oobbas')[0];
    window.AppSdkInfo.setAppName(appName);
    let extras: any = {};
    extras = this.setCorrelationId(extras);
    if (isRegistration) {
      extras['oobRefId'] = oobRefId;
      extras[window.AppSdk.EXTRA_KEY_QR_SUPPORTED] = true;
    }
    return appSdk.processOob(window.location.href, extras);
  }

  async qrPasswordlessSignin(params: IBiomtericCallbacks) {
    try {
      const userName = localDataStorage.getItem('BIOMETRIC_USERNAME') || '';
      const webUrl = window.document.location.origin + '/oobbas?weboob=AUTH';
      let extras = {
        qrType: window.QRType.UNIVERSAL_ANY_RP,
        oobRefId: 'Authorize FIDO2 Authenticator',
        webUrl,
      };

      extras = this.setCorrelationId(extras);

      const sessionData = {
        userName,
      };
      window.biometricCallbacks = { ...params, ...(window.biometricCallbacks || {}) };

      const controller = new window.AuthenticationController(sessionData, this.mAdaptiveCore, null, extras);
      const mainController = new CustomMethodUIFactory();
      window.MethodUIFactory.setInstance(mainController);

      const view = new CustomAdaptiveAuthenticationUI(controller);
      const res = await view.enable(window.AutoStart.AUTO_NONE);

      const data = {
        sessionKey: res.sessionData?.sessionKey,
        username: userName,
      };

      if (data.sessionKey) {
        await this.login(data);
      }

      return data;
    } catch (error) {

      this.handleError(error)
    } finally {
      window.biometricCallbacks = {};
    }
  }
}

const BiometricAuthService = new Service();

export { BiometricAuthService };
