import React, { HTMLProps, ReactNode } from 'react';
import clsx from 'clsx';

interface Props extends HTMLProps<HTMLButtonElement> {
  variant?: 'contained' | 'outlined' | 'text' | 'link';
  color?: 'primary' | 'secondary' | 'text';
  type?: 'submit' | 'button' | 'reset' | undefined;
  underline?: boolean;
  children: ReactNode;
  testId?: string
  ariaLabel?: string | null
}

function Button(props: Props) {
  const { className, variant = 'contained', color = 'primary', type = 'button', underline, children, testId, ariaLabel, ...restProps } = props;

  return (
    <button
      className={clsx(
        variant === 'contained' && 'flex justify-center rounded-3xl px-8 py-3 text-[15px] font-medium leading-[15px]',
        variant === 'outlined' && 'flex justify-center rounded-3xl px-8 py-3 text-[15px] font-medium leading-[15px] ',
        // variant === 'text' && 'font-bold',
        variant === 'link' && 'underline decoration-1 underline-offset-2',

        color === 'primary' && 'bg-primary text-light hover:bg-action-primary-hover disabled:bg-action-disabled disabled:bg-opacity-50',
        color === 'secondary' && 'border-2 border-primary bg-default text-primary',
        color === 'text' && 'text-primary',
        underline && 'underline-offset-2 hover:underline',
        className
      )}
      type={type}
      data-testid={testId ?? "test-button"}
      aria-label={ariaLabel ?? undefined}
      {...restProps}
    >
      {children}
    </button>
  );
}

export { Button };
