import { IBasRegisteredDevice, IBiomtericCallbacks } from 'hooks/useBiometric/types';

declare global {
  interface Window {
    AppSdk: any;
    MethodUIFactory: any;
    AdaptiveUI: any;
    AppSdkInfo: any;
    AutoStart: any;
    OOBMethodUI: any;
    QRType: any;
    AdaptiveCore: any;
    ManageRegistrationsController: any;
    UserDataCache: any;
    SuggestRegisterUIStatus: any;
    SuggestRegistrationController: any;
    AuthenticationController: any;
    AdaptiveType: any;
    biometricCallbacks: IBiomtericCallbacks;
    customOobMethodUI: {
      finish: () => void;
    };
  }
}

export class CustomSuggestRegistrationUI {
  suggestRegController: any;
  constructor(controller: any) {
    this.suggestRegController = controller;
  }

  askPermission() {
    this.suggestRegController.processAnswer(this, window.SuggestRegisterUIStatus.YES);
  }

  onOperationCompleted(outcome: string) {
    console.log('Suggest register onOperationCompleted outcome - ' + outcome);
  }

  setMethods(methods: any[]) {
    if (methods.length === 1) {
      // Only 1 method is defined in Registration Sequences
      this.suggestRegController.doRegister(this, methods[0]);
    }
  }
}

export class CustomManageRegistrationsUI {
  mControllder: any;
  constructor(controller: any) {
    this.mControllder = controller;
  }
  setMethods(registrations: IBasRegisteredDevice[] = []) {
    const reg = registrations?.map((devices: any) => {
      return {
        ...devices,
        onRemove: () => {
          return this.mControllder.onRemove(devices.handle, devices.protocol, this);
        },
        onRename: (input = '') => {
          return this.mControllder.onRename(devices.handle, input.trim(), this);
        },
      };
    });
    window.biometricCallbacks?.onRegisteredDevicesListner?.(reg);
  }

  onOperationCompleted(outcome: string, message: string) {
    console.log('onOperationCompleted. Outcome - ' + outcome + ' | Message - ' + message);
  }

  onRenameCompleted(outcome: string, message: string) {
    console.log('onRenameCompleted. Outcome - ' + outcome + ' | Message - ' + message);
  }

  onRemoveCompleted(outcome: string, message: string) {
    console.log('onRemoveCompleted. Outcome - ' + outcome + ' | Message - ' + message);
  }
}

export class CustomOobUi {
  onOperationStarted(result: string, data: any) {
    console.log('onOperationStarted', result, data);
    window.biometricCallbacks?.onQrOperationStarted?.(result, data);
  }

  onOobReceived(...param: any[]) {
    window.biometricCallbacks?.onQrOobReceived?.(param);
  }

  onOperationCompleted(...param: any[]) {
    window.biometricCallbacks?.onQrOperationCompleted?.(param);
  }

  onFinish(...param: any[]) {
    console.log('param: ', param);
    window.biometricCallbacks?.onQrFinish?.(param);
  }
}

export class CustomMethodUIFactory {
  prevMethodUIFactory = window.MethodUIFactory.getInstance();
  createMethodUI(methodType: any, extras: any) {
    const oobMethodUI = new window.OOBMethodUI(extras);
    console.log('oobMethodUI: ', oobMethodUI);
    const oobLiveData = new CustomOobUi();
    console.log('oobLiveData: ', oobLiveData);

    oobMethodUI.setLiveData(oobLiveData);

    window.customOobMethodUI = oobMethodUI;
    // console.log('oobLiveData: ', oobLiveData);
    return oobMethodUI;
  }
  isSupported(methodType) {
    return this.prevMethodUIFactory.isSupported(methodType);
  }

  getInstructions(methodType) {
    return this.prevMethodUIFactory.getInstructions(methodType);
  }

  getGroups() {
    return this.prevMethodUIFactory.getGroups();
  }

  getTitle(methodGroup, opType) {
    return this.prevMethodUIFactory.getTitle(methodGroup, opType);
  }
}

export class CustomAdaptiveAuthenticationUI {
  controller: any;

  constructor(controller: any) {
    this.controller = controller;
  }

  enable(autoAuth: any) {
    return this.controller.enable(autoAuth, this);
  }

  setMethods(methods: any[]) {
    if (methods.length == 0) return;
    const methodGroup = methods?.[0];
    this.controller.doAuthenticate(this, methodGroup);
  }

  onOperationCompleted(outcome: string) {
    console.log('Suggest register onOperationCompleted outcome - ' + outcome);
  }
}
