import React from 'react';
import { Typography } from 'components/Typography';
import InfoFilled from 'icons/InfoFilled';

export interface ErrorToastProps {
  title: string;
  message?: string;
}

export const ErrorToast = (props: ErrorToastProps) => {
  return (
    <div className="m-4 flex flex-row gap-4 border-t-4 border-t-primary bg-white p-4 shadow-[0px_2px_6px_#B7B7B7]">
      <InfoFilled width={20} height={20} />
      <div>
        {props.title && (
          <Typography variant="body2" component="p" className="font-semibold text-primary">
            {props.title}
          </Typography>
        )}
        {!!props.message && (
          <Typography variant="body1" component="p" className="mt-2">
            {props.message}
          </Typography>
        )}
      </div>
    </div>
  );
};
