import axios from 'axios';
import { useBiometricState } from 'hooks/useBiometric';
import { useEffect } from 'react';
import { create } from 'zustand';

interface FeatureFlags  {
      ENV: 'development' | 'staging' | 'production'
      BIOMETRIC_AUTH: boolean
      MARKET: "US" | "AUS"
}

export const useFeatureFlagState = create<{
  features: FeatureFlags
  setFeatures: (param: FeatureFlags) => void;
}>((set) => ({
  features: {
    ENV: 'development',
    BIOMETRIC_AUTH: false,
    MARKET: "US"
  },
  setFeatures: (param: FeatureFlags) => set({ features: param }),
}));

export function useFeatureFlagStore() {
  const setFeatures = useFeatureFlagState((st) => st.setFeatures);
  const setBiometricFlow = useBiometricState((st) => st.setBiometricFlow);

  useEffect(() => {
    axios.get('/config.json').then((res) => {
      setFeatures(res.data);
      if (res?.data?.BIOMETRIC_AUTH) {
        setBiometricFlow(true);
      } else {
        setBiometricFlow(false);
      }
    });
  }, []);
}

export const useMarketStore = (): 'US' | 'AUS' => {
  const featuresStore = useFeatureFlagState();

  const market = featuresStore.features?.MARKET || 'US';

  return market;
}
