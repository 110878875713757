import { BiometricAuthService } from 'utils/biometric';
import service from 'libs/axios';
import { useEffect, useState } from 'react';
import { useGetCustomerDetails } from 'services';
import { create } from 'zustand';
import { IBasRegisteredDevice, IBiometricState, IQrData } from './types';
import { localDataStorage } from "../../utils/storage/LocalStorage";
import { Toast } from 'components/CustomToast';

export const useBiometricState = create<IBiometricState>((set) => ({
  isBiometricFlowEnabled: false,
  skdStatus: 'init_pending',
  error: undefined,
  user: undefined,
  currentDeviceId: '',
  registeredDevices: [],
  isDevicesFetched: false,
  setBiometricFlow: (param: boolean) => set({ isBiometricFlowEnabled: param }),
  setIsDevicesFetched: (param: boolean) => set({ isDevicesFetched: param }),
  setUser: (param) => set({ user: param }),
  setSkdStatus: (param) => set({ skdStatus: param }),
  setError: (param: any) => set({ error: param }),
  setCurrentDeviceID: (param: string) => set({ currentDeviceId: param }),
  setRegisteredDevices: (devices: IBasRegisteredDevice[] = []) => set({ registeredDevices: devices }),
}));

export function useBiometricStore({ disableDeviceFetch = false, forceInitalization = false } = {}) {
  const state = useBiometricState();
  const user = state.user;
  const { data: userInfo, refetch } = useGetCustomerDetails();
  const [qrData, setQrData] = useState<IQrData>();

  const isSwitchEnabled = state.user?.isBiometricEnabled == 'Y';

  const enableBiometric = async () => {
    await service({
      method: 'post',
      url: `/customers/settings`,
      data: {
        isBiometricEnabled: 'Y',
      },
    });
    await Promise.allSettled([refetch(), fetchDevice(), localDataStorage.setItem('BIOMETRIC_USERNAME', user?.email || '')]);
  };

  const disableBiometric = async () => {
    await service({
      method: 'post',
      url: `/customers/settings`,
      data: {
        isBiometricEnabled: 'N',
      },
    });
    await refetch();
    localDataStorage.removeItem('BIOMETRIC_USERNAME');
  };

  const fetchDevice = async () => {
    await BiometricAuthService.getRegisteredDevice();
    state.setIsDevicesFetched(true);
  };

  const removeBiometricUser = async () => {
    localDataStorage.removeItem('BIOMETRIC_USERNAME');
  };

  const registerDevice = async () => {
    const { error } = await BiometricAuthService.register();
    if (error) {
      console.error(error);
      throw new Error(error);
    } else {
      await fetchDevice();
      await enableBiometric();
    }
  };

  const registerWithQrCode = async ({
    onSuccess = (data: any) => {
      //
    },
  } = {}) => {
    const response = await BiometricAuthService.registerWithQrCode({
      onQrOperationStarted: (result, data) => {
        setQrData(data);
      },
      onQrFinish: () => {
        setQrData(undefined);
      },
    });
    if (response?.outcome == 'SUCCESS') {
      onSuccess(response);
      setQrData(undefined);
      enableBiometric();
    }
  };

  const cancelRegisterWithQrCode = async () => {
    setQrData(undefined);
    window.customOobMethodUI?.finish?.();
  };

  const getCurrentDeviceId = async () => {
    const deviceId = await BiometricAuthService.getDeviceId();
    state.setCurrentDeviceID(deviceId as string);
  };

  const isCurrentDeviceRegistered = async () => {
    const deviceId = await BiometricAuthService.getDeviceId();
    return state.registeredDevices.some((_) => _.device == deviceId);
  };

  useEffect(() => {
    if (userInfo) {
      state.setUser(userInfo as any);
    }
  }, [userInfo]);

  const checkShouldAutoDisableBiometric = () => {
    try {
      if (!!state.user && !state.registeredDevices.length && isSwitchEnabled) {
        console.log('Automatically disabling...');
        disableBiometric();
      } else {
        console.log('Not is the logs');
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    if (localDataStorage.getItem('BIOMETRIC_USERNAME'))
      checkShouldAutoDisableBiometric();
  }, [state.registeredDevices]);

  const canAuthenticateUsingThisDevice = async () => {
    return await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
  };

  const authenticateQrUsingThisDevice = async (isRegistration: boolean) => {
    return await BiometricAuthService.qrAuthorize(isRegistration);
  };

  const passwordlessSignin = async () => {
    const username = localDataStorage.getItem('BIOMETRIC_USERNAME');
    const res = await BiometricAuthService.passwordlessSignin(username as string);
    if (res) {
      return res;
    } else {
      throw new Error(res);
    }
  };

  const qrPasswordlessSignin = async () => {
    const res = await BiometricAuthService.qrPasswordlessSignin({
      onQrOperationStarted: (result, data) => {
        setQrData(data);
      },
      // Uncomment if event is required
      // onQrOobReceived: () => {},
      onQrFinish: () => {
        setQrData(undefined);
      },
    });

    if (res) {
      return res;
    } else {
      throw new Error(res);
    }
  };


  useEffect(() => {
    if (state.isBiometricFlowEnabled) {
      BiometricAuthService.init({
        forceInitalization,
        onRegisteredDevicesListner: function (basDevices) {
          state.setRegisteredDevices(basDevices);
        },
      }).then(() => {
        // init_pending means first intitalization of listners
        // hence fetching registered devices
        if (!disableDeviceFetch && !state.isDevicesFetched) {
          fetchDevice();
        }
        state.setSkdStatus('completed');
        getCurrentDeviceId();
      })
        .catch((err: any) => {
          state.setSkdStatus('error');
          state.setBiometricFlow(false);
          state.setError(err)
          Toast.error({
            title: 'Authentication failed',
            message: err?.message || 'Please try again later',
          });
        });
    }
  }, []);


  return {
    qrData,
    isBiometricFlowEnabled: state.isBiometricFlowEnabled,
    registerWithQrCode,
    authenticateQrUsingThisDevice,
    user: state.user,
    canAuthenticateUsingThisDevice,
    cancelRegisterWithQrCode,
    isSwitchEnabled,
    registeredDevices: state.registeredDevices,
    registerDevice,
    enableBiometric,
    passwordlessSignin,
    removeBiometricUser,
    isCurrentDeviceRegistered,
    checkShouldAutoDisableBiometric,
    disableBiometric,
    fetchDevice,
    qrPasswordlessSignin,
    error: state?.error,
  };
}
