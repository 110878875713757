interface LocalDataStorage {
  [key: string]: string | null;
}

class LocalStorage {
  storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  setItem = (key: string, value: string) => {
    this.storage.setItem(key, value);
  };

  getItem = (key: string) => {
    return this.storage.getItem(key);
  };

  removeItem = (key: string) => {
    return this.storage.removeItem(key);
  };


  length = () => {
    return this.storage.length;
  };

  getAllItems = () => {
    const storageLength = this.length();

    if (!storageLength) return null;

    const list: LocalDataStorage = {};
    for (let i = 0; i < storageLength; i++) {
      const key = this.storage.key(i);

      if (key) {
        const value = this.getItem(key);
        list[key] = value;
      }
    }

    return list;
  };
}

const localDataStorage = new LocalStorage();

export { localDataStorage };
